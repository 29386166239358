<template>
  <div class="login">
    <div ref="loading" class="loading">
      <div class="text">登录中...</div>
    </div>
  </div>
</template>

<script>
  import login from "@/api/login";
  import sidebar from "@/api/sidebar";
  import lottie from "lottie-web";
  import loadingJson from "@/assets/animation/loading.json";

  export default {
    name: "login",
    data() {
      return {
        loading: "",
      };
    },
    methods: {
      //第一次进入向微信重定向获取code
      async getWxCode(opId, state) {
        let res = await login.getSession(true);
        if (
          res.code === "0" &&
          res.data &&
          localStorage.getItem("platform_user")
        ) {
          await this.getEnter(this.judgeEnter);
        } else {
          let { code, data } = await login.getCorpId({ opId }, true);
          if (code === "0") {
            localStorage.setItem("platform_opId", opId);
            localStorage.setItem("platform_corpId", data.corpId);
            localStorage.setItem("platform_agentId", data.agentId);
            let url = encodeURIComponent(window.location.origin + "/#/transit");
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${data.corpId}&redirect_uri=${url}&response_type=code&scope=snsapi_privateinfo&agentid=${data.agentId}&state=${state}#wechat_redirect`;
          }
        }
      },
      //调取微信sdk判断入口方式
      async getEnter(cb) {
        let suiteId = localStorage.getItem("platform_agentId");
        let corpId = localStorage.getItem("platform_corpId");
        let opId = localStorage.getItem("platform_opId");
        const targetUrl = location.href.split("#")[0];
        let params = { url: targetUrl, suiteId, corpId, opId };
        let res = await login.getEnter(params, true);
        if (res.code === "0") {
          this.$wx.config({
            beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
            appId: corpId, // 必填，企业微信的corpID
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.noncestr, // 必填，生成签名的随机串
            signature: res.data.configSignature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
            jsApiList: ["chooseImage"], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
          });
          this.$wx.ready(() => {
            this.$wx.agentConfig({
              corpid: corpId, // 必填，企业微信的corpid，必须与当前登录的企业一致
              agentid: suiteId, // 必填，企业微信的应用id （e.g. 1000247）
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.agentConfigSignature, // 必填，签名，见附录-JS-SDK使用权限签名算法
              jsApiList: ["getCurExternalContact", "getContext"], //必填，传入需要使用的接口名称  !!!后续要使用getCurExternalContact故加入
              success: (res) => {
                console.log("agentConfig 注册成功", res);
                //侧边栏进入判断入口
                try {
                  this.$wx.invoke("getContext", {}, (res) => {
                    if (res.err_msg == "getContext:ok") {
                      localStorage.setItem("platform_sidebar", res.entry);
                      cb();
                    } else {
                      //错误处理
                      alert(res.err_msg);
                    }
                  });
                } catch (e) {
                  console.log(e, "兼容pc无invoke方法");
                  localStorage.setItem("platform_sidebar", "normal"); //PC端默认设置为normal
                  cb();
                }
                // 回调
              },
              fail: (res) => {
                if (res.errMsg === "agentConfig:fail") {
                  console.log(res, "兼容pc无agentConfig方法");
                  localStorage.setItem("platform_sidebar", "normal"); //PC端默认设置为normal
                  cb();
                }
                if (res.errMsg.indexOf("function not exist") > -1) {
                  alert("版本过低请升级");
                }
                console.log(`agentConfig 注册失败===>>>${res.errMsg}`);
              },
            });
          });
        }
      },
      //微信跳转回来拿到code和state做登录
      async wxCallCode() {
        let query = this.$route.query;
        let parmas = {
          code: query.code,
          opId: localStorage.getItem("platform_opId"),
        };
        let { code, data } = await login.authLogin(parmas, true);
        if (code === "0") {
          localStorage.setItem("platform_user", JSON.stringify(data));
          if (data.token) {
            let { code } = await login.setToken(data.token, true);
            if (code === "0") {
              await this.judgeEnter();
            }
          } else {
            let redirect =
              this.$route.query.redirect ||
              (this.$route.query.state &&
                JSON.parse(this.$route.query.state).redirect) ||
              "";
            await this.$router.push({
              path: `/nopermission?state=${
                JSON.parse(query.state).jumpCme
              }&redirect=${redirect}`,
            });
          }
        }
      },
      //根据进入方式(侧边栏 or 应用内)走相应流程
      async judgeEnter() {
        //       contact_profile	从联系人详情进入
        //       single_chat_tools	从单聊会话的工具栏进入
        //       group_chat_tools	从群聊会话的工具栏进入
        //       chat_attachment	从会话的聊天附件栏进入（企业微信3.1.6及以上版本才返回）
        //       normal	除以上场景之外进入，例如工作台，聊天会话等
        //判断是否是侧边栏进入，不是则走正常流程
        if (localStorage.getItem("platform_sidebar") !== "normal") {
          this.$wx.invoke("getCurExternalContact", {}, async (res) => {
            this.jumpSidebar(res);
          });
        } else {
          let opId = localStorage.getItem("platform_opId");
          let user = JSON.parse(localStorage.getItem("platform_user"));
          let { code, data } = await login.authority({ opId }, true);
          if (code === "0") {
            if (
              !localStorage.getItem("platform_estateId") ||
              !data.some(
                (item) =>
                  item.estateId === localStorage.getItem("platform_estateId")
              )
            ) {
              localStorage.setItem("platform_estateId", data[0].estateId);
              localStorage.setItem("platform_estateName", data[0].estateName);
              localStorage.setItem(
                "platform_role",
                JSON.stringify({
                  saleRoleName: data[0].roleAuthorities[0].saleRoleName,
                  saleUserId: data[0].roleAuthorities[0].saleUserId,
                  name: user.name,
                })
              );
            }
            //获取应用权限
            let res = await login.appAuthority(
              { estateId: localStorage.getItem("platform_estateId") },
              true
            );
            if (res.code === "0") {
              let appPermit = res.data.map((item) => item.permit);
              localStorage.setItem(
                "platform_appPermit",
                JSON.stringify(appPermit)
              );
              localStorage.setItem("platform_appNews", JSON.stringify(res.data));
            }
            if (
              this.$route.query.redirect ||
              (this.$route.query.state &&
                JSON.parse(this.$route.query.state).redirect)
            ) {
              window.location.href =
                this.$route.query.redirect ||
                JSON.parse(this.$route.query.state).redirect;
            } else if (localStorage.getItem("redirect")) {
              let redirect = localStorage.getItem("redirect");
              localStorage.removeItem("redirect");
              window.location.href = decodeURIComponent(redirect);
            } else {
              let role = JSON.parse(localStorage.getItem("platform_role"));
              if (role && role.saleRoleName === "销售经理") {
                this.$router.push("/manager");
              } else {
                this.$router.push("/consultant");
              }
            }
          }
        }
      },
      //侧边栏流程(crm&潜客)
      async jumpSidebar(res) {
        if (res.err_msg == "getCurExternalContact:ok") {
          //缓存侧边栏进入外部联系人userId
          localStorage.setItem("platform_qwExternalUserid", res.userId);
          let { code, data } = await sidebar.getSidebarEstates(
            {
              opId: localStorage.getItem("platform_opId"),
            },
            true
          );
          if (code === "0") {
            if (
              !localStorage.getItem("platform_sidebarEstateId") ||
              !data.some(
                (item) =>
                  item.estateId ===
                  localStorage.getItem("platform_sidebarEstateId")
              )
            ) {
              localStorage.setItem("platform_sidebarEstateId", data[0].estateId);
            }
            // 当前query的state能否转为字符串
            let stateStrObg =
              this.$route.query.state &&
              this.$route.query.state.indexOf("{") > -1;
            if (
              this.$route.query.state === "CME" ||
              (stateStrObg &&
                this.$route.query.state &&
                JSON.parse(this.$route.query.state).jumpCme === "CME")
            ) {
              let jumpHost = `${window.location.protocol}//` + location.host;
              window.location.href = jumpHost + "/cme/#/sidebar";
            } else if (
              this.$route.query.state === "TALKCODE" ||
              (stateStrObg &&
                this.$route.query.state &&
                JSON.parse(this.$route.query.state).jumpCme === "TALKCODE")
            ) {
              let jumpHost = `${window.location.protocol}//` + location.host;
              window.location.href = jumpHost + "/#/talkCode";
            } else {
              await this.judgeAppPermit();
            }
          }
        } else {
          alert("获取userid失败:" + res.err_msg);
          //错误处理
        }
      },
      //侧边栏流程 跳转应用逻辑
      async judgeAppPermit() {
        //获取应用权限
        let res = await login.appAuthority(
          { estateId: localStorage.getItem("platform_sidebarEstateId") },
          true
        );
        if (res.code === "0") {
          let appPermit = res.data.map((item) => item.permit);
          if (appPermit.some((item) => item === "potential")) {
            let params = {
              qwExternalUserid: localStorage.getItem("platform_qwExternalUserid"),
              estateId: localStorage.getItem("platform_sidebarEstateId"),
              opId: localStorage.getItem("platform_opId"),
            };
            //先判断潜客归属,再看是否跳转scrm;
            let { code, data } = await sidebar.sidebarBelongClue(params, true);
            if (code === "0") {
              if (data.hasConverted === 1) {
                await this.jumpScrm(appPermit, data);
              } else if (data.hasConverted === 0) {
                await this.jumpPotential(data);
              }
            }
          } else {
            let jumpHost = `${window.location.protocol}//` + location.host;
            window.location.href =
              jumpHost + `/#/abnormal?code=${-1000}&msg=当前项目未启用潜客宝`;
          }
        }
      },

      //跳转scrm详情
      async jumpScrm(appPermit, data) {
        if (appPermit.some((item) => item === "scrm")) {
          let val = await sidebar.sidebarBelong(
            {
              estateId: localStorage.getItem("platform_sidebarEstateId"),
              clueId: data.clueId,
              opId: localStorage.getItem("platform_opId"),
            },
            true
          );
          //判断用户归属
          if (val.code === "0") {
            let jumpHost = `${window.location.protocol}//` + location.host;
            switch (val.data.judgeFlag) {
              case 1:
                window.location.href = jumpHost + "/crm/#/sidebar/turnClient";
                break;
              case 2:
                window.location.href = jumpHost + "/crm/#/sidebar/noClient";
                break;
              case 3:
                window.location.href =
                  jumpHost + `/crm/#/sidebar/detail?oppId=${val.data.oppId}`;
                break;
              case 4:
                window.location.href = jumpHost + "/crm/#/sidebar/turnClient";
                break;
              default:
                break;
            }
          }
        } else {
          let jumpHost = `${window.location.protocol}//` + location.host;
          window.location.href =
            jumpHost +
            `/#/abnormal?code=${-1000}&msg=该好友已转化为客户，当前项目未启用CRM`;
        }
      },
      //跳转潜客详情
      async jumpPotential(data) {
        let jumpHost = `${window.location.protocol}//` + location.host;
        switch (data.clueBelongFlag) {
          case 0:
            window.location.href = jumpHost + "/potential/#/sidebar/noClient";
            break;
          case 1:
            window.location.href =
              jumpHost + `/potential/#/sidebar/detail?clueId=${data.clueId}`;
            break;
          default:
            break;
        }
      },
    },
    mounted() {
      this.loading = lottie.loadAnimation({
        container: this.$refs.loading,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: loadingJson,
      });
      this.loading.play();
      let params = this.$route.query;
      console.log("初始state", params);
      let state = JSON.stringify({
        jumpCme: params.state || "",
        redirect: params.redirect || "",
      });
      if (params.opId) {
        this.getWxCode(params.opId, state);
      } else if (params.code && params.state) {
        this.getEnter(this.wxCallCode);
      } else {
        console.error("参数异常");
      }
    },
    beforeDestroy() {
      this.loading.stop();
    },
  };
</script>

<style lang="less" scoped>
.login {
  background: #ffffff;
  min-height: 100vh;
  display: flex;
  justify-content: center;

  .loading {
    margin-top: 80px;
    width: 300px;
    height: 300px;
    position: relative;

    .text {
      left: 40%;
      bottom: 10%;
      position: absolute;
      line-height: 24px;
      margin-top: 8px;
      color: #303133;
      font-weight: bold;
      font-size: 17px;
      text-align: center;
    }
  }
}
</style>
